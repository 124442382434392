<template>
  <div class="page-box">
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="订单号" prop="orderNo">
          <a-input v-model="queryRef.orderNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="收益类型" prop="incomeType">
          <a-select v-model="queryRef.incomeType" placeholder='请选择' style="width: 120px">
            <a-select-option v-for="item in incomeType" :value="item.value" :key='item.value'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="结算状态" prop="settlementState">
          <a-select v-model="queryRef.settlementState" placeholder='请选择' style="width: 120px">
            <a-select-option v-for="item in settlementState" :value="item.value" :key='item.value'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="订单状态" prop="orderState">
          <a-select v-model="queryRef.orderState" placeholder='请选择' style="width: 120px">
            <a-select-option v-for="item in orderStates" :value="item.value" :key='item.value'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="商品名称" prop="productName">
          <a-input v-model="queryRef.productName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="推客姓名" prop="sharerName">
          <a-input v-model="queryRef.sharerName" placeholder="请输入" />
        </a-form-model-item>
        <a-row>
          <a-form-model-item label="下单时间" prop="orderTime">
            <a-range-picker v-model="orderTime" />
          </a-form-model-item>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
            今
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
            昨
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
            近7天
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
            近30天
          </a-button>
          <a-form-model-item class="screen-btns">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
    <div style='margin-bottom:16px'>
      <a-button type='primary' ghost :loading='exportLoading' @click='handleExport'>导出列表</a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.promoteOrderInfoId" :loading="loadingRef" @change="handleTableChange" :scroll="{ x: 1500 }">
      <template #productName="text,record">
        <div class="goods-info goods" @click='$router.push(`/goods/goods_detail/${record.productId}`)'>
          <base-img class="goods-img" :src="record.productSkuPic||record.productMainPic" width="60" height="60" oss-style="60_60" />
          <a-tooltip :title="record.productName">
            <p class="goods-name el2" type="link">{{record.productName}}</p>
          </a-tooltip>
        </div>
      </template>
      <template #orderState="record">
        {{orderStates.find(x=>Number(x.value)===record).name}}
      </template>
      <template #incomeType="record">
        {{incomeType.find(x=>Number(x.value)===record).name}}
      </template>
      <template #settlementState="record">
        {{settlementState.find(x=>Number(x.value)===record).name}}
      </template>
    </base-table>
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { marketing } from '@/api'
import { incomeType, settlementState, orderStates } from '../../utils/constants'
import { useExport } from '@/utils/tools'
const columns = [
  {
    title: '订单号',
    fixed: 'left',
    width: 220,
    dataIndex: 'orderNo',
  },
  {
    title: '商品',
    width: 220,
    dataIndex: 'productName',
    scopedSlots: { customRender: 'productName' },
  },
  {
    title: '推客姓名',
    width: 100,
    dataIndex: 'userName',
  },
  {
    title: '被邀请用户',
    width: 160,
    dataIndex: 'buyNickName',
  },
  {
    title: '数量',
    width: 80,
    dataIndex: 'count',
  },
  {
    title: '支付金额(元)',
    width: 160,
    dataIndex: 'subtotalAmount',
  },
  {
    title: '订单状态',
    width: 100,
    dataIndex: 'orderState',
    scopedSlots: { customRender: 'orderState' },
  },
  {
    title: '收益类型',
    width: 100,
    dataIndex: 'incomeType',
    scopedSlots: { customRender: 'incomeType' },
  },
  {
    title: '推广收益',
    width: 100,
    dataIndex: 'shareAmount',
  },
  {
    title: '合伙人收益',
    width: 200,
    dataIndex: 'partnerAmount'
  },
  {
    title: '高级合伙人收益',
    width: 200,
    dataIndex: 'highPartnerAmount'
  },
  {
    title: '收益状态',
    width: 100,
    dataIndex: 'settlementState',
    scopedSlots: { customRender: 'settlementState' },
  },
]

export default {
  name: 'PageGoodsOrder',

  setup (props, { root }) {
    const formRef = ref(null)
    const { exporting, exportFn } = useExport(() => marketing.exportOrderList(queryRef.value))
    const orderTime = ref([])
    watch(
      () => orderTime.value,
      (data) => {
        if (data.length) {
          queryRef.value.startTime = `${moment(orderTime.value[0]).format('YYYY-MM-DD')} 00:00:00`
          queryRef.value.endTime = `${moment(orderTime.value[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          queryRef.value.startTime = ''
          queryRef.value.endTime = ''
        }
      }
    )

    const queryRef = ref({
      orderNo: '',
      productName: '',
      startTime: '',
      endTime: '',
      incomeType: undefined,
      settlementState: undefined,
      sharerName: '',
      orderState: undefined,
    })
    onMounted(() => {
      getTableData()
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const loadingRef = ref(false)
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await marketing.getGoodsOrder({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false
      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      orderTime.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleDateShortcut (day) {
      orderTime.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }

    return {
      columns,
      formRef,
      orderTime,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      getTableData,
      handleSearch,
      handleReset,
      handleTableChange,
      handleDateShortcut,
      incomeType,
      settlementState,
      orderStates,
      exportLoading: exporting,
      handleExport: exportFn,
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.goods-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  .goods-img {
    flex-shrink: 0;
  }
  .goods-name {
    max-width: 100px;
    margin-left: 12px;
    padding: 0;
    display: block;
    color: #1890ff;
  }
}
</style>
